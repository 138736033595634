import React from 'react'
import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

const Message = ({ open, onClose, message, status }) => {
  const Alert = (props) => {
    return <MuiAlert variant="filled" {...props} />
  }
  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={onClose} message={message} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert onClose={onClose} severity={status}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Message
